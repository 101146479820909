export const Bio = {
    name: "Myra Anna",
    roles: [
        "Software Engineer", 
        "Full Stack Developer",
    ],
    description: 
    "Hey there! 👋 I'm a software geek on a mission to make cool things happen in the tech world! 🚀 With a knack for end-to-end software development, I've got my hands dirty in blockchain, cybersecurity, and data analytics. Consider me a quick learner, a team player extraordinaire, and someone who's always up for a coding adventure.",
    github: "https://github.com/myraAnna",
    resume: "https://drive.google.com/file/d/1wi5zgJKbzYmNBjFDRU3NuI-UqyoQf47z/view?usp=drive_link",
    linkedin: "https://www.linkedin.com/in/myra-annatasha-myr98/",
};
