import styled from 'styled-components';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FaGithub } from "react-icons/fa";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Bio } from '../../data/bio';

const FooterContainer = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  
`; //background: #100F0D;

const FooterWrapper = styled.footer`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 0.5rem;
  color: ${({ theme }) => theme.text_primary};
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Copyright = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.soft2};
  text-align: center;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterWrapper>
        <p>Let's collaborate and shape the future of tech together!</p> 
        <p>Connect with me to explore new possibilities.</p>
        <SocialMediaIcons>
          <SocialMediaIcon href={Bio.github} target="display"><FaGithub /></SocialMediaIcon>
          <SocialMediaIcon href={Bio.linkedin} target="display"><LinkedInIcon /></SocialMediaIcon>
          <SocialMediaIcon href="mailto:myraannatasha.myr@gmail.com" target="display"><EmailOutlinedIcon /></SocialMediaIcon>
        </SocialMediaIcons>
        <Copyright>
          &copy; 2024 Myra Annatasha.
        </Copyright>

      </FooterWrapper>
    </FooterContainer>
  );
}

export default Footer;