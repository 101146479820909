export const darkTheme = {
    bg:"#191919",
    bgLight: "#1B131B",
    primary:"#4184A9", 
    text_primary:"#FFA2AD",
    text_secondary:"#FFFFFF",
    card:"#070707",
    card_light: '#1B131B',
    button:"#4184A9", 
    white:"#C6C6C6",
    black:"#000000",
}

export const lightTheme = {
    bg:"#C6C6C6",
    bgLight: "#FFA2AD",
    primary:"#4184A9", 
    text_primary:"#191919",
    text_secondary:"#FFFFFF",
    card:"#C6C6C6",
    button:"#4184A9", 
    white:"#FFFFFF",
    black:"#000000",
}