import React from 'react'; //, { useState }
import { Container, Wrapper, Title, Desc, CardContainer } from './ProjectStyle'; //, ToggleButtonGroup, ToggleButton, Divider  
import ProjectCard from '../Cards/ProjectCard';
import { projects } from '../../data/projects';

const Projects = ({openModal,setOpenModal}) => {
    //const [toggle, setToggle] = useState('all');
    return (
      <Container id="projects">
        <Wrapper>
          <Title>Projects</Title>
          <Desc>
            Explore some of my projects where I've applied these skills: 
          </Desc>

          <CardContainer>
            {projects.map((project, index) => (
              <ProjectCard key={index} project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
          </CardContainer>

        {/*
          <ToggleButtonGroup>
            {toggle === 'all' ?
              <ToggleButton active value="all" onClick={() => setToggle('all')}>All</ToggleButton>
              :
              <ToggleButton value="all" onClick={() => setToggle('all')}>All</ToggleButton>
            }
            <Divider />

            {toggle === 'web app' ?
              <ToggleButton active value="web app" onClick={() => setToggle('web app')}>WEB APP'S</ToggleButton>
              :
              <ToggleButton value="web app" onClick={() => setToggle('web app')}>WEB APP'S</ToggleButton>
            }
            <Divider />

            {toggle === 'gbl app' ?
              <ToggleButton active value="gbl app" onClick={() => setToggle('gbl app')}>GAME BASED LEARNING APP'S</ToggleButton>
              :
              <ToggleButton value="gbl app" onClick={() => setToggle('gbl app')}>GAME BASED LEARNING APP'S</ToggleButton>
            }
            <Divider />

            {toggle === 'bc' ?
              <ToggleButton active value="bc" onClick={() => setToggle('bc')}>WEB3/BLOCKCHAIN</ToggleButton>
              :
              <ToggleButton value="bc" onClick={() => setToggle('bc')}>WEB3/BLOCKCHAIN</ToggleButton>
            }
          </ToggleButtonGroup>
        */}

        {/*
          <CardContainer>
            {toggle === 'all' && projects
              .map((project) => (
                <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
              ))}
            {projects
              .filter((item) => item.category === toggle)
              .map((project) => (
                <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
              ))}
          </CardContainer>
        */}
      
        </Wrapper>
      </Container>
    )
}
  
export default Projects;