export const projects = [
    {
        id: 0,
        title: "Thirst Milkshake Web Application (Full Stack Web Development)",
        date: "September 2019 - January 2020",
        desc: "Collaborated with Thirst’s founder, Mr. Izuwan Rabaie, to successfully develop Laravel framework of e-commerce application for Thirst Milkshake & Smoothies Bar.",
        img: 
            "https://user-images.githubusercontent.com/86984336/291226048-58607a0a-9b61-463b-b91c-9ba6f4a43af1.PNG",
        tags: [
            "Laravel", 
            "PHP", 
            "MySQL", 
            "HTML", 
            "CSS", 
            "JavaScript",
            "Bootstrap",
        ],
        category: "web app",
        github: "https://github.com/myraAnna/ThirstKCH",
        webapp: "https://myraanna.github.io/ThirstKCH/",
    },
    {
        id: 1,
        title: "Evaluation of a Hybrid Agile Approach and Information System (IS) Success Model in Game-Based Learning",
        date: "September 2020 - Jun 2021",
        desc: "This study discusses the success of applying the Speak Out as Game-Based Learning (GBL) application using the Hybrid Agile approach and Information System (IS) Success model to assess human factors and technological factors in behavioral intention and influence them on individual performance. IBM SPSS® Statistics software was used for data analysis in this study to establish a relationship between dependent and independent variables (R) of the proposed Information System (IS) Success Model.",
        img: "https://i.ibb.co/Kr1Z7LQ/3.png",
        tags: [
            "C#",
            "Unity",
            "IBM SPSS", 
            "Agile", 
        ],
        category: "gbl app",
        github: "",
        webapp: "https://play.unity.com/mg/other/speak-up",
    },
    {
        id: 2,
        title: "Embedded Trust in BDI-based Social Simulation Through Blockchain Technology",
        date: "November 2021 - Present",
        desc: " Conducted research on the adoption of blockchain technology in AI applications to address concerns around user acceptance and trust issues.",
        img: "https://i.ibb.co/HnvZPTN/5.jpg",
        tags: [
            "Blockchain", 
            "Artificial Intelligence", 
            "Web3 Development",
        ],
        category: "bc",
        github: "",
        webapp: "",
    },
    {
        id: 3,
        title: "Portfolio Website For Myself",
        date: "",
        desc: "Coding my own portfolio website using ReactJS and TailwindCSS, and deploying it on Cloudflare Pages.",
        img: "https://i.ibb.co/kcCgBCd/4.png",
        tags: [
            "ReactJS", 
            "CSS", 
            "TailwindCSS",
            "HTML", 
            "CSS", 
            "JavaScript"
        ],
        category: "web app",
        github: "",
    },
    {
        id: 4,
        title: "DateTime in ReactJS",
        date: "",
        desc: "DateTime Picker component using Material-UI (MUI) and Material-UI X (MUI X) in ReactJS.",
        img: 
            "https://user-images.githubusercontent.com/86984336/291908598-16b76931-651a-4cfc-bd79-cce3cb41997c.png",
        tags: [
            "ReactJS", 
            "HTML", 
            "CSS", 
            "JavaScript",
        ],
        category: "web app",
        github: "https://github.com/myraAnna/dateTime",
    },
    {
        id: 5,
        title: "ReactJS Login Register Page",
        date: "",
        desc: "Login and Register page using ReactJS, responsive design with form validation. Utilized LottiFiles, Framer Motion, Material-UI, and React Icons.",
        img: 
            "https://user-images.githubusercontent.com/86984336/296165622-1a07771b-e479-4e86-b025-d454e4f1f844.png",
        tags: [
            "ReactJS", 
            "HTML", 
            "CSS", 
            "JavaScript",
        ],
        category: "web app",
        github: "https://github.com/myraAnna/project-react"
    },
]