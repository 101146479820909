import React from "react";
import { Container, Wrapper, Desc, InContainer, InnerContainer, LeftContainer, RightContainer, SubTitle, Title } from "./AboutMeStyle";
import { About } from "../../data/aboutMe";
import AboutMeCard from "../Cards/AboutMeCard";

const AboutMe = () => {
    return ( 
            <Container id="about">
                <Wrapper>
                    <Title>About Me</Title>
                    <Desc>Here's a peek into what defines me 👋</Desc>
                    <InContainer>
                        <InnerContainer>
                            <LeftContainer>
                                <SubTitle>
                                    {"\"" + About.description + "\""}
                                </SubTitle>
                            </LeftContainer>

                            <RightContainer>
                                <AboutMeCard About={About}/>
                            </RightContainer>
                        </InnerContainer>
                    </InContainer>
                </Wrapper>
            </Container>
    )
};

export default AboutMe;