export const Experiences = [
    {
        id: 0,
        img: "https://dbazzar.com/landing_assets/image/Sains_1.png",
        role: "Intern Trainee",
        company: "Sarawak Information Systems Sdn. Bhd. (SAINS)",
        date: "January 2020 - July 2020",
        desc: [
            "Developed a robust CRUD web application adhering to OWASP standards using Bootstrap, PHP, and MySQL in the CodeIgniter framework.",
            "Designed a Python Pandas system for efficient data extraction from XML files to .docs files.",
            "Conducted comprehensive research in computer forensics, mastering forensic tools on Linux and Windows.",
            "Improved a by creating a streamlined PHP7 and MySQL application for managing physical forensics evidence",
        ],
        skills: [
            "Computer Forensics",
            "Ethical Hacking",
            "Python Pandas",
            "CodeIgniter",
            "HTML",
            "CSS",
            "PHP",
            "MySQL",
        ],
        doc: "",
    },
];