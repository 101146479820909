import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-directio: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
`;  

export const Wrapper = styled.div`
    max-width: 1100px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0px 80px 0;
    gap: 12px;  
`;

export const Desc = styled.div`
    font-size: 18px;
    max-width: 700px;
    text-align: center;
    color: ${({ theme }) => theme.text_secondary};

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`;

export const InContainer = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0px 30px;

    @media screen and (max-width: 960px) {
        padding: 0px 16px;
    }

    @media screen and (max-width: 640px) {
        padding: 0px 16px;
    }

    z-index: 1;
`;

export const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1100px;

    @media screen and (max-width: 960px) {
        flex-direction: column;
    }
`;

export const LeftContainer = styled.div`
    width: 100%;
    order: 1;

    @media screen and (max-width: 960px) {
        order: 2;
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    @media screen and (max-width: 640px) {
        order: 2;
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`;

export const RightContainer = styled.div`
    width: 100%;
    order: 2;
    display: flex;
    justify-content: end;
    gap: 12px;

    @media screen and (max-width: 960px) {
        order: 1;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;
    }

    @media screen and (max-width: 640px) {
        order: 1;
        margin-bottom: 30px;
    }
`;

export const SubTitle = styled.div`
    color: ${({ theme }) => theme.text_primary+95};
    fontStyle: italic;
    fontWeight: bold;
    paddingLeft: 10px;
    marginBottom: 20px;
    line-height: 32px;
    margin-bottom: 42px;

    @media screen and (max-width: 960px) {
        text-align: center;
    }

    @media screen and (max-width: 640px) {
        font-size: 16px;
        line-height: 32px;
    }
`;

export const Title= styled.div`
    font-size: 42px;
    font-weight: 600;
    text-align: center;
    color: ${({ theme }) => theme.text_primary};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;