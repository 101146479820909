import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import { darkTheme } from './utils/Themes';

import Navbar from './components/Navbar';
import Hero from './components/HeroSection';
import AboutMe from './components/AboutMe';
import Skills from './components/Skills';
import Experience from './components/Experience';
import Education from './components/Education';
import Project from './components/Project';
import ProjectDetails from './components/Project/ProjectDetails';
import Footer from './components/Footer';


const Body = styled.div`
  width: 100%;
  overflow-x: hidden;
`; //background-color: ${({ theme }) => theme.bg};

const Wrapper = styled.div`
  background-image: linear-gradient(180deg, #1B131B 0%, #070707 55%, #1B131B 100%);
`;

const Section = styled.div`
  scroll-snap-align: center;
`; //height: 100vh;

function App() {
  //const [darkMode, setDarkMode] = useState(true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  console.log(openModal)
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Navbar />
        <Body >
          <Wrapper>
            <Section>
              <Hero/>
            </Section>
            <AboutMe />
              <Skills />
              <Experience />
            <Project openModal={openModal} setOpenModal={setOpenModal}/>
              <Education /> 
              <Footer />  
          </Wrapper>
          {openModal.state &&
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
          }    
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;
