export const education = [
    {
        id: 0,
        img: "",
        school: "Universiti Malaysia Sarawak (UNIMAS)",
        date: "September 2017 - July 2021",
        grade: "CGPA: 3.49",
        desc: "",
        degree: "Bachelor of Computer Science (Software Engineering) with Honours",
    },
    {
        id: 1,
        img: "",
        school: "Universiti Malaysia Sarawak (UNIMAS)",
        date: "October 2021 - Ongoing",
        grade: "Research Mode",
        desc: "Research Title: Embedded Trust in BDI based Social Simulation Through Blockchain Technology",
        degree: "Master of Computer Science (Software Engineering)",
    },
]