import React from 'react';
import { Container, Wrapper, Title, Desc, TimelineSection } from './ExperienceStyle';
import Timeline  from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ExperienceCard from '../Cards/ExperienceCard';
import { Experiences } from '../../data/experiences';

const Experience = () => {
    return <Container id="experience">
        <Wrapper>
            <Title>Experiences</Title>
            <Desc>Embarking on the journey of my professional development.</Desc>
            <TimelineSection>
                <Timeline>
                    { Experiences.map((experience, index) => (
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot variant="outlined" color="secondary" />
                                {index !== Experiences.length - 1 && <TimelineConnector style={{ background: '#854CE6' }} />}
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <ExperienceCard experience={experience}/>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </TimelineSection>
        </Wrapper>
    </Container>
};

export default Experience;