import React from "react";
import { HeroContainer, HeroInnerContainer, HeroLeftContainer, HeroRightContainer, Title, TextLoop, Span, SubTitle, ResumeButton } from "./HeroStyle";
import Typewriter from "typewriter-effect";
import { Bio } from "../../data/bio";
import Resume from '../../assets/documents/Myra Annatasha Umang - Resume.pdf';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    scroll-behavior: smooth;
  }
`;

const Hero = () => {
    const handleDownload = (event) => {
        event.preventDefault();
        window.open(Resume);
    };

    return ( 
        <>  
            <GlobalStyle />
            <div id="about">
                <HeroContainer>
                    <HeroInnerContainer>
                        <HeroLeftContainer>
                            <Title>
                                Hi, I am <br />
                                {Bio.name}
                            </Title>
                            <TextLoop>
                                I am a
                                <Span>
                                    <Typewriter 
                                        options={{
                                            strings: Bio.roles,
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />
                                </Span>
                            </TextLoop>
                            <SubTitle>
                                {Bio.description}
                            </SubTitle>
                            <ResumeButton 
                                href="#"
                                target='_blank' 
                                rel='noreferrer'
                                onClick={handleDownload}
                            >Download Resume</ResumeButton>
                        </HeroLeftContainer>
                        <HeroRightContainer>
                        <dotlottie-player
                            src="https://lottie.host/7d024da5-bde7-481b-9ffe-1d55167f41e3/fTeOnslCZu.json"
                            background="transparent"
                            speed="1"
                            style={{ width: '450px', height: '450px' }}
                            loop
                            autoplay
                            ></dotlottie-player>
                        </HeroRightContainer>
                    </HeroInnerContainer>
                </HeroContainer>
            </div>
        </>
    )
};

export default Hero;