export const About = {
    description: 
    "Software engineering isn't just a career—it's my canvas to express unwavering passion for technology. Seeking opportunities to shape the future by applying knowledge, dedication, and curiosity.",
    skills: [
        {
            lottie: "https://lottie.host/5a8bbd81-d714-401c-b1c4-099543e1975d/ev8GzvxDWw.json",
            name: "Tech Enthusiast",
            desc: "Constantly exploring and experimenting with web and mobile app development, also blockchain development.",
        },
        {
            lottie: "https://lottie.host/6846c0e0-3a3d-4b7d-80cb-64137e29630b/9qVQMz45Ic.json",
            name: "Dreamer",
            desc: "Aspiring to contribute to the tech world with creativity and meaningful solutions.",
        },
        {
            lottie: "https://lottie.host/c74ca881-8bec-4498-9813-10147175017a/jGc9pTzZQt.json",
            name: "Adaptability",
            desc: "Quick to adapt to new technologies, frameworks, or methodologies in a dynamic tech landscape.",
        },
    ]

};
