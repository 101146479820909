import React from "react";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import logo from '../../assets/images/logo.png';
import { FaBars } from "react-icons/fa";
import { Bio } from "../../data/bio";
import { FaGithubSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const Nav = styled.div`
    background-color: ${({ theme }) => theme.card_light};
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    z-index: 1;
    width: 100%;
    max-width: 1200px;
    padding: 0 24px;
`;

const NavLogo = styled(LinkR)`
    width: 80%;    
    padding: 0 6px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
    @media (max-width: 640px) {
        padding: 0 0px;
    }
`;

const MobileIcon = styled.div`
    display: none;
    
    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 50%);
        font-size: 1.5rem;
        cursor: pointer;
        color: ${({ theme }) => theme.text_primary};
    }
`;


const NavMenu = styled.ul`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 32px;
    padding: 0 6px;

    @media screen and (max-width: 760px) {
        display: none;
    }
`;

const NavLink = styled.a`
    color: ${({ theme }) => theme.text_primary};
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        color: ${({ theme }) => theme.primary};
    }

    &.active {
        border-bottom: 2px solid ${({ theme }) => theme.primary};
    }
`;

/* const IconButton = styled.div`
    border: 1.8px solid ${({ theme }) => theme.primary};
    justify-content: center;
    display: flex;
    align-items: center;
    height: 70%;
    border-radius: 20px;
    color: ${({ theme }) => theme.primary};
    cursor: pointer;
    padding: 0 20px;
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
    transition: all 0.6s ease-in-out;

    &:hover {
        background: ${({ theme }) => theme.primary};
        color: ${({ theme }) => theme.white};     
    }
    @media screen and (max-width: 768px) { 
    font-size: 14px;
    }
`; /*

/*const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    width: 80%;
    height: 100%;
    padding: 0 6px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`; */

export const Span = styled.span`
    padding: 0 4px;
    font-weight: bold;
    font-size: 18px;
`;

const MobileMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    padding: 12px 40px 24px 40px;
    background: ${({ theme }) => theme.card_light+99};
    transition: all 0.6s ease-in-out;
    transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-100%)')};
    border-radius: 0 0 20px 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    z-index: ${({ isOpen }) => (isOpen ? '1000' : '-1000')};
`;

const MobileLink = styled(LinkR)`
    color: ${({ theme }) => theme.text_primary};
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: ${({ theme }) => theme.primary};
    }
`;

const SocialMediaIcons = styled.div`
    display: flex;
    margin-top: 1rem;

    @media screen and (max-width: 768px) {
    display: none;
    }
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Navbar = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    //const theme = useTheme();
    return (
        <Nav>
            <NavContainer>
                <NavLogo to="/">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            color: "white",
                            marginBottom: "20:",
                            cursor: "pointer",
                        }}
                    >
                        <img 
                            src={logo} 
                            alt="Myra Anna." 
                            style={{ width: "60px", height: "60px", marginRight: "10px" }} 
                        />
                        <Span>Myra Anna.</Span>
                    </div>
                </NavLogo>
                <MobileIcon>
                    <FaBars onClick={() => {setIsOpen(!isOpen)}} />
                </MobileIcon>
                <NavMenu>
                    <NavLink href="#about">About</NavLink>
                    <NavLink href="#skills">Skills</NavLink>
                    <NavLink href="#experience">Experience</NavLink>
                    <NavLink href="#projects">Projects</NavLink>
                    <NavLink href="#education">Education</NavLink>
                </NavMenu>
                <SocialMediaIcons>
                    <SocialMediaIcon href={Bio.github} target="display"><FaGithubSquare /></SocialMediaIcon>
                    <SocialMediaIcon href={Bio.linkedin} target="display"><FaLinkedin  /></SocialMediaIcon>
                </SocialMediaIcons>
                {
                    isOpen &&
                    <MobileMenu isOpen={isOpen}>
                        <MobileLink href="#about" onClick={() => {
                        setIsOpen(!isOpen)
                        }}>About</MobileLink>
                        <MobileLink href='#skills' onClick={() => {
                        setIsOpen(!isOpen)
                        }}>Skills</MobileLink>
                        <MobileLink href='#experience' onClick={() => {
                        setIsOpen(!isOpen)
                        }}>Experience</MobileLink>
                        <MobileLink href='#projects' onClick={() => {
                        setIsOpen(!isOpen)
                        }}>Projects</MobileLink>
                        <MobileLink href='#education' onClick={() => {
                        setIsOpen(!isOpen)
                        }}>Education</MobileLink>
                        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                            <a style={{padding: '5px 5px', color: 'white', width: 'max-content', marginRight: '10px'}} href={Bio.github} target="display">
                                <FaGithubSquare size={30} />
                            </a>
                            <a style={{padding: '5px 5px', color: 'white', width: 'max-content'}} href={Bio.linkedin} target="display">
                                <FaLinkedin size={30} />
                            </a>
                        </div>
                    </MobileMenu>
                }
            </NavContainer>
        </Nav> 
    );
};
export default Navbar;

