import React from 'react';
import styled from "styled-components";

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const SubName = styled.h3`
    color: ${({ theme }) => theme.text_primary+95};
    line-height: 32px;

    @media screen and (max-width: 960px) {
        text-align: center;
    }

    @media screen and (max-width: 640px) {
        font-size: 16px;
        line-height: 32px;
    }
`;

const SubDesc = styled.p`
    color: ${({ theme }) => theme.text_primary+95};

    @media screen and (max-width: 960px) {
        text-align: center;
    }

    @media screen and (max-width: 640px) {
        font-size: 16px;
    }
`;

const sectionStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '10px',
  padding: '20px',
  
};

const lottieStyle = {
  flex: 1,
  marginRight: '20px',
};

const contentStyle = {
  flex: 2,
};

const AboutMeCard = ({ About }) => {
  return (
    <div style={containerStyle}>
      {About.skills.map((skills, index) => (
        <div key={index} style={sectionStyle}>
          <div style={lottieStyle}>
            <dotlottie-player
              src={skills.lottie}
              speed="0.5"
              style={{ width: '80%', height: '80%' }}
              loop
              autoplay
            ></dotlottie-player>
          </div>
          <div style={contentStyle}>
            <SubName>{skills.name}</SubName>
            <SubDesc>{skills.desc}</SubDesc>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AboutMeCard;